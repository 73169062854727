.header {
  background-color: transparent;
  font-size: 1.8rem;
  position: absolute;
  color: white;
  padding: 10px 20px;
}
.main.error-page {
  width: 1070px;
  margin: 80px auto 0;
}
#error {
  text-align: left;
}
#error > h1 {
  font-size: 7em;
  font-weight: bold;
}
body {
  padding-top: 0px;
}
.my-footer {
  position: relative;
  top: 29px;
  height: 29px;
}
#container404_1 {
  background: url('/static/images/404/404bg_1.jpg') no-repeat center 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: white;
  padding-top: 130px;
  min-height: 104.5%;
  font-weight: bold;
  height: 100vh;
}
#container404_1 h1 {
  font-size: 200px;
  font-weight: bold;
}
#container404_1 #errorDetails {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 8px 14px;
}
#container404_1 h2 {
  font-size: 38px;
  font-weight: 100;
  text-shadow: 1px 1px #3D3C49;
  margin-top: 0px;
}
#container404_1 p a {
  color: #b0e7ff;
  text-shadow: 1px 1px #3D3C49;
  text-decoration: underline;
  font-size: 22px;
  font-weight: 100;
}
#container404_1 #BTWSection {
  background-color: white;
  color: #404040;
  padding: 10px 15px 12px;
  margin-top: 10px;
  display: inline-block;
}
#container404_1 #BTWSection a {
  color: #f32196;
}
