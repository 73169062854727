@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "variables";

.header{
  background-color: transparent;
  .giantText;
  position: absolute;
  color: white;
  padding: 10px 20px;
}
.main.error-page{
  width: 1070px;
  margin: 80px auto 0;
}
#error{
  text-align: left;
}
#error > h1{
  font-size: 7em;
  font-weight: bold;
}

#error > h2{
  //font-size: 2.5em;
}

body{
  padding-top: 0px;
}

.my-footer{
  position: relative;
  top: 29px;
  height: 29px;
}
#container404_1{
  background: url('/static/images/404/404bg_1.jpg') no-repeat center 0px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: white;
  padding-top: 130px;
  min-height: 104.5%;
  font-weight: bold;
  height: 100vh;
  h1{
    font-size: 200px;
    font-weight: bold

  }
  #errorDetails{
    background-color: rgba(0,0,0,0.6);
    padding: 8px 14px;
  }
  h2{
    font-size: 38px;
    font-weight: 100;
    text-shadow: 1px 1px @color1;
    margin-top: 0px;
  }
  p a{
    color: @color0_lightest;
    text-shadow: 1px 1px @color1;
    text-decoration: underline;
    font-size: 22px;
    font-weight: 100;
  }
  #BTWSection{
    background-color: white;
    color: @textColor;
    padding: 10px 15px 12px;
    margin-top: 10px;
    display: inline-block;
    a{
      color: @color2;
    }
  }
}
